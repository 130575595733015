<template>
	<div class="bg-gray-900">
		<navbar/>
		<div class="flex flex-grow">
			<sidebar/>
			<div class="flex flex-col flex-grow flex-shrink min-h-screen" style="padding-top: 2.8rem;">
				<div class="pt-1" style="min-height: 590px">
					<div class="bg-gray-900 shadow-sm p-5  bg-opacity-60 flex items-center justify-between text-gray-400">
						<div class="text-xl text-gray-300">{{company}}</div>
						<div class="flex items-center">
							<div class="mr-5 text-sm"><i class="icon-calendar2 mr-2"></i>{{date}}</div>
							<div class="mr-1 text-sm"><i class="icon-watch2 mr-2"></i>{{time}}</div>
						</div>
					</div>
					<transition name="fade" mode="out-in">
						<router-view/>
					</transition>
				</div>
				<div class="bg-gray-900 p-5 text-gray-400 bottom-0 fixed w-full">
					© 2023 dwitamaelektrindo 
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
import { mapGetters } from 'vuex'
export default {
	components:  {
		Navbar,
		Sidebar
	},
	data () {
		return {
			date: '',
			time: '',
		}
	},
	mounted () {
		this.getTime()
	},

	computed: {
		...mapGetters({
			company: 'auth/company'
		})
	},
	methods: {
		getTime () {
			let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
			let bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
			let today = new Date()
			let day = today.getDay()
			let date = today.getDate()
			let month = today.getMonth()
			let year = today.getFullYear()
			let HH = today.getHours()
			let mm = today.getMinutes()
			let ss = today.getSeconds()
			mm = checkTime(mm)
			ss = checkTime(ss)
			this.date = `${hari[day]}, ${date} ${bulan[month]} ${year}`
			this.time = `${HH}:${mm}:${ss}`
			setTimeout(() => {
				this.getTime()
			}, 1000)
			function checkTime (i) {
				if(i < 10) {
					i = `0${i}`
				}
				return i
			}
		},
	}
	
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
transition-duration: 0.3s;
transition-property: opacity;
transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
opacity: 0
}
</style>